import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "courses",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#courses",
        "aria-label": "courses permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Courses`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/courses/hlt-1"
        }}>{`LING 529 (Human Language Technology I)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/courses/snlp-1"
        }}>{`LING 439/539 (Statistical Natural Language Processing)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/courses/snlp-2"
        }}>{`LING 582 (Advanced Statistical Natural Language Processing)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/courses/hlt-pro"
        }}>{`LING 689 (Professionalism for HLT)`}</a></li>
    </ul>
    <p>{`NOTE: These courses are all offered in an asynchronous online format.`}</p>
    <h2 {...{
      "id": "workshops",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#workshops",
        "aria-label": "workshops permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Workshops`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/courses/general/configuring-your-development-environment"
        }}>{`Setting up a development environment for NLP`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/workshops/resbaz-2022/intro-to-nlp"
        }}>{`Intro to NLP: Representing words and documents as vectors (ResBaz 2022)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://arizona-linguistics.github.io/ailt-intro-to-databases/colang/",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Introduction to relational databases (CoLang 2024)`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Developed and taught with `}<a parentName="li" {...{
              "href": "https://linguistics.arizona.edu/person/amy-fountain",
              "target": "_self",
              "rel": "nofollow"
            }}>{`Amy Fountain`}</a>{` and `}<a parentName="li" {...{
              "href": "https://ailt.arizona.edu/team#john-ivens",
              "target": "_self",
              "rel": "nofollow"
            }}>{`John Ivens`}</a>{` as part of the `}<a parentName="li" {...{
              "href": "https://ailt.arizona.edu/",
              "target": "_self",
              "rel": "nofollow"
            }}>{`AILT initiative`}</a></li>
        </ul>
      </li>
    </ul>
    <h1 {...{
      "id": "programs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#programs",
        "aria-label": "programs permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Programs`}</h1>
    <p>{`I direct a couple of graduate programs at the University of Arizona.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://uazhlt.github.io/hlt-online/about",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Online MS in Human Language Technology (HLT)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://uazhlt.github.io/nlp-cert/about",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Graduate Certificate Program in Natural Language Processing (NLP)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      